import {Box} from "@chakra-ui/react"
import {motion} from "framer-motion"
import React, {useCallback, useState} from "react"
import {Controlled as ControlledZoom} from 'react-medium-image-zoom'
import {GatsbyImage} from "gatsby-plugin-image";

const AnimatedBox = motion(Box)

const MyZoom = ({img}) => {
    const [isZoomed, setIsZoomed] = useState(false)

    const handleImgLoad = useCallback(() => {
        setIsZoomed(true)
    }, [])

    const handleZoomChange = useCallback(shouldZoom => {
        setIsZoomed(shouldZoom)
    }, [])

    return (
        <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
            <AnimatedBox ratio={img.childImageSharp.gatsbyImageData} w="full" overflow="hidden" onClick={handleImgLoad}
                         cursor="zoom-in">
                <AnimatedBox ratio={img.childImageSharp.gatsbyImageData} w="full" whileHover={{scale: 1.05}}>
                    <GatsbyImage
                        alt="Art Photo"
                        image={img.childImageSharp.gatsbyImageData}
                        loading="eager"
                        backgroundColor="#fff"
                        draggable={false}/>

                </AnimatedBox>

            </AnimatedBox>
        </ControlledZoom>
    );
}

export {MyZoom}
