import React, { useEffect, useState } from "react"
import uuid from 'uuid-random';
import { Flex, useBreakpointValue} from "@chakra-ui/react"
import {MyZoom} from "./MyZoom"
import _orderBy from 'lodash/orderBy'

const gap = 3

const MyMasonry = ({images}) => {
    const colNum = useBreakpointValue({ base: 2, lg: 3 })
    const [columns, setColumns] = useState(null)

    useEffect(() => {
        const result = []
        for (let i = 0; i < (colNum || 3); i++){
            result.push([])
        }
        let i = 0;
        for(let pic of _orderBy(images, ['name'], ['asc'])) {
            result[i].push(pic)
            i = i + 1 < (colNum || 3) ? i + 1 : 0
        }
        setColumns(result)
    }, [colNum, images])

    return <Flex w="full" p={gap} gridGap={gap}>
        {columns && columns.map((colImages) =>
            <Flex key={uuid()} direction="column" w="full" gridGap={gap}>
                {colImages.map((image, index) => {
                    console.log(colImages)
                    return <MyZoom key={index} img={image}/>
                    //  return <Text>kurac</Text>
                })}
            </Flex>
        )}
    </Flex>
}

export {MyMasonry}
